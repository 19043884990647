<template>
  <div class="helpsWrap">
    <div class="searchWrap">
      <span class="label">问题： </span>
      <el-input v-model="instruction_name" placeholder="请输入内容"></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>
    <el-row :gutter="20" class="documents">
      <el-col
        :span="4"
        v-for="d in documentsData.result || []"
        :key="d.id"
        class="document"
        ><div class="grid-content bg-purple-light">
          <div class="title">
            {{ d.question }}
          </div>
          <div class="content" v-html="d.answer" />
          <div>
            <span
              :href="d.href"
              class="href"
              @click="
                $router.push({
                  path: '/helps/edit',
                  query: { id: d.id, noEdit: true },
                })
              "
              >查看详情></span
            >
          </div>
        </div></el-col
      >
    </el-row>

    <div class="footer">
      <Pagination
        v-if="documentsData.total > 12"
        :handleSizeChange="handleSizeChange"
        :handleCurrentPageChange="handleCurrentPageChange"
        :total="documentsData.total"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  data() {
    return {
      instruction_name: "",
      documentsData: {},
    };
  },
  components: {
    Pagination,
  },
  mounted() {
    this.queryHelpDocumentsList({});
  },
  methods: {
    handleSizeChange(v) {
      this.queryHelpDocumentsList({ page_size: v });
    },
    handleCurrentPageChange(v) {
      this.queryHelpDocumentsList({ page_num: v });
    },
    queryHelpDocumentsList({ page_num = 1, page_size = 12, instruction_name }) {
      this.$request
        .queryHelpDocumentsList({
          page_num,
          page_size,
          instruction_name,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.documentsData = res?.data || {};
        });
    },
    search() {
      this.queryHelpDocumentsList({ instruction_name: this.instruction_name });
    },
  },
};
</script>

<style lang='scss' scoped>
.helpsWrap {
  background-color: #fff;
  .searchWrap {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 0;
    align-items: center;
    .label {
      margin-left: 16px;
    }
    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .documents {
    padding: 24px 24px 0;
    .document {
      border-radius: 8px;
      margin-bottom: 24px;
      .title {
        font-size: 18px;
      }
      .content {
        color: #989898;
        margin: 12px 6px;
        word-break: break-all;
        text-overflow: ellipsis;
        max-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        height: 40px;
      }
      .href {
        color: #409eff !important;
        cursor: pointer;
      }
    }
  }
  .footer {
    padding: 0 24px 24px;
  }

  .el-row {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    padding: 16px;
  }
}
</style>